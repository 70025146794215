"use client"

import type { FunctionComponent } from "react"

import { useTranslation } from "@/modules/i18n/components/useTranslation"

import { Error } from "./Error"

export const ErrorWithLazyTitle: FunctionComponent = () => {
  const { t, ready } = useTranslation(["shared_server"], true)

  const title = ready ? t("shared_server.error_pages.internal_error.message") : "Internal server error"

  return <Error title={title} />
}
