"use client"

import type { ErrorComponent } from "@/app/types"

import { useEffect } from "react"

import { TranslationProvider } from "@/modules/i18n/components/TranslationProvider"
import { ErrorWithLazyTitle } from "@/modules/layouts/components/Errors/ErrorWithLazyTitle"
import { logger } from "@/modules/monitoring/logger"
import { AppTrackingPageViewPropsUpdater } from "@/modules/tracking/components/AppTrackingPageViewPropsUpdater"

export const ErrorPage: ErrorComponent = ({ error }) => {
  useEffect(() => {
    logger.error(error)
  }, [error])

  return (
    <AppTrackingPageViewPropsUpdater>
      <div style={{ padding: "3rem" }}>
        <TranslationProvider>
          <ErrorWithLazyTitle />
        </TranslationProvider>
      </div>
    </AppTrackingPageViewPropsUpdater>
  )
}

export default ErrorPage
